import { createApp } from 'vue';
import App from './App.vue';
import router from './router/routes';
import { createPinia } from 'pinia';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faVuejs, faReact, faWordpress, faNode } from '@fortawesome/free-brands-svg-icons';

import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import Dialog from 'vue3-dialog'

import { Chart, registerables } from 'chart.js';

const startApp = async () => {
  const app = createApp(App);
  const pinia = createPinia();


  library.add(faVuejs, faReact, faWordpress, faNode);
  app.component('font-awesome-icon',FontAwesomeIcon)


  app.use(router);
  app.use(pinia);
  app.use(Toast);
  app.use(Dialog)

  const dialog = app.config.globalProperties.$dialog;
  app.provide('$dialog',dialog)

  Chart.register(...registerables);


  app.mount('#app');
};

startApp(); 
