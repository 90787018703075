<template>
  <Pie
    id="my-chart-id"
    :options="chartOptions"
    :data="chartData"
  />
</template>

<script>
import { defineComponent, ref } from 'vue'
import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale)

export default defineComponent({
name: 'PieChart',
components: { Pie },
props: {
  chartData: {
    type: Object,
    required: true
  }
},
setup() {
  const chartOptions = ref({
    responsive: true,
    cutout: '50%', 
  })

  return {
    chartOptions
  }
}
})
</script>
