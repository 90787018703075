<template>
  <div class="row" style="width: 100%;">
    <div class="col-sm-12 col-md-3">
      <SidebarMenu />
    </div>
    <div class="col-8 main-content" v-if="isAuthenticated">
      <div class="welcome-message">
        <h2>Welcome, {{ user.name }}</h2>
      </div>
      <div class="org-button-container" v-if="!orgSelected && !dbUserInfo?.organizationId">
        <button 
          :class="['btn', { 'active-btn': creatingOrg }, 'btn-default']"
          @click="showCreateOrg"
        >
          Create Organization
        </button>
        <button 
          :class="['btn', { 'active-btn': joiningOrg }, 'btn-default']"
          @click="showJoinOrg"
        >
          Join Organization
        </button>
      </div>

      <form class="org-form" v-if="creatingOrg" @submit.prevent="createOrganization">
        <div class="form-group">
          <label for="orgName" class="form-label">Organization Name:</label>
          <input 
            type="text" 
            id="orgName" 
            v-model="organizationName" 
            class="form-control" 
            placeholder="Enter organization name" 
            required 
          />

          <label for="orgEmail" class="form-label">Organization Email:</label>
          <p class="info-text">The organization email will be used when a user submits a new question to the FAQ.</p>
          <input 
            type="text" 
            id="orgEmail" 
            v-model="organizationEmail" 
            class="form-control" 
            placeholder="Enter organization email" 
            required 
          />
          <button type="submit" class="submit-btn">Create Organization</button>
        </div>
      </form>

      <form class="org-form" v-if="joiningOrg" @submit.prevent="joinOrganization">
        <div class="form-group">
          <label for="orgCode" class="form-label">Organization Code:</label>
          <input 
            type="text" 
            id="orgCode" 
            v-model="organizationCode" 
            class="form-control" 
            placeholder="Enter organization code" 
            required 
          />
          <button type="submit" class="submit-btn">Join Organization</button>
        </div>
      </form>
      
      <div v-for="(organization, index) in dbOrgInfo" :key="index">
        <div class="active-org-info">
          <div>
            <h1 class="org-details">{{ organization.organizationName }}</h1>
            <p class="org-details">{{ organization.organizationEmail }}</p>
            <div @click="openAPIModal(index, organization.apiKey)">
              <p class="api-key">View API Key
                <i style="font-size:1.2rem; transform: rotate(90deg);" class="api-key bi bi-key"></i>
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <button @click="manageUsers(organization)" class="btn btn-default org-action-buttons text-start">
              Manage Users
            </button>
            <button @click="viewFAQ(organization)" class="btn btn-default org-action-buttons text-start">
              View FAQ
            </button>
          </div>
        </div>
      </div>

      <div v-if="isOpen && activeOrgIndex !== null" class="modal">
        <div class="modal-content">
          <span class="close" @click="closeAPIModal">&times;</span>
          <h2 class="org-details">API Key</h2>
          <div class="input-container">
            <input type="text" v-model="apiKey" readonly />
            <i class="bi bi-copy copy-icon" @click="copyAPIKey"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { useAuthStore } from '@/stores/authStore';
import SidebarMenu from '@/components/SidebarMenu.vue';
import { useToast } from 'vue-toastification';


export default {
  name: 'Organizations',
  components: {
    SidebarMenu,
  },
  setup() {
    const router = useRouter();
    const authStore = useAuthStore();
    const userStore = useUserStore();

    const toast = useToast();
    const toastOptions = { position: 'bottom-left', timeout: 1400 };

    const isAuthenticated = ref(authStore.isAuthenticated);
    const user = ref(authStore.user);

    const isOpen = ref(false);
    const activeOrgIndex = ref(null);
    const apiKey = ref('');

    const dbUserInfo = ref(null);
    const dbOrgInfo = ref([]);
    const orgSelected = ref(false);
    const creatingOrg = ref(false);
    const joiningOrg = ref(false);
    const organizationName = ref('');
    const organizationEmail = ref('');
    const organizationCode = ref('');

    const openAPIModal = (index, key) => {
      isOpen.value = true;
      activeOrgIndex.value = index;
      apiKey.value = key;
    };

    const closeAPIModal = () => {
      isOpen.value = false;
      activeOrgIndex.value = null;
      apiKey.value = '';
    };

    const copyAPIKey = () => {
      navigator.clipboard.writeText(apiKey.value)
        .then(() => alert(`Copied the API key: ${apiKey.value}`))
        .catch(err => console.error('Failed to copy API key:', err));
    };

    const showCreateOrg = () => {
      creatingOrg.value = true;
      joiningOrg.value = false;
    };

    const showJoinOrg = () => {
      creatingOrg.value = false;
      joiningOrg.value = true;
    };

    const createOrganization = async () => {
      try {
        if(dbOrgInfo.value.length >= authStore.userPermissions.organizations){
          toast.warning('Please upgrade your subscription to create an organization.',toastOptions);
          throw new Error('Subscription Tier does not allow creation of orgs');
        }else{
          const response = await fetch('/organization/create-organization', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: user.value.sub,
            organizationName: organizationName.value,
            organizationEmail: organizationEmail.value,
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Unknown error');
        }
        orgSelected.value = true;
        await getOrganizationInfo();
        }
      } catch (error) {
        console.error('Failed to create organization:', error);
      }
    };

    const joinOrganization = async () => {
      try {
        if (organizationCode.value) {
          const response = await fetch('/organization/join-organization', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userId: user.value.sub,
              organizationCode: organizationCode.value,
            }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Unknown error');
          }else {
            toast.success("Your request to join this organization has been sent!",toastOptions)
          }
        }
      } catch (error) {
        console.error('Failed to join organization:', error);
        toast.error(`${error}`, toastOptions)
      }
    };

    const getUserInfo = async () => {
      try {
        const response = await fetch(`/user/get-user-info?userId=${encodeURIComponent(user.value.sub)}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Unknown error');
        }
        dbUserInfo.value = await response.json();
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    const getOrganizationInfo = async () => {
      try {
        const response = await fetch(`/organization/get-organization-info?userId=${encodeURIComponent(user.value.sub)}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Unknown error');
        }

        dbOrgInfo.value = await response.json();
      } catch (error) {
        console.error('Failed to fetch organization info:', error);
      }
    };

    const viewFAQ = (organization) => {
      router.push(`/organizations/faq/${organization.organizationId}`);
    };

    const manageUsers = (organization) => {
      router.push(`/organizations/manage-users/${organization.organizationId}`);
    };

    onMounted(async () => {
      if (isAuthenticated.value) {
        await getUserInfo();
        if (dbUserInfo.value?.affiliatedOrg) {
          await getOrganizationInfo();
        }
      }
    });

    return {
      organizationName,
      organizationEmail,
      organizationCode,
      orgSelected,
      creatingOrg,
      joiningOrg,
      showCreateOrg,
      showJoinOrg,
      createOrganization,
      joinOrganization,
      user,
      isAuthenticated,
      dbUserInfo,
      dbOrgInfo,
      viewFAQ,
      manageUsers,
      isOpen,
      activeOrgIndex,
      apiKey,
      openAPIModal,
      closeAPIModal,
      copyAPIKey,
    };
  },
};
</script>

<style scoped>
form {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 10px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.welcome-message {
  display: flex;
  justify-content: center;
}

.main-content {
  background-color: #f4f6f8;
  min-height: 100vh;
}

.welcome-message h2 {
  font-weight: bold;
  color: #1584ea;
  text-align: center;
  margin-bottom: 20px;
}

.org-button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.org-form {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.form-label {
  font-weight: 600;
  color: #333333;
}

.info-text {
  font-size: 12px;
  color: #777777;
  margin-bottom: 10px;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  box-sizing: border-box;
}

.submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #9dccf6;
  color: black;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #1584ea;
  color: white;
}

.active-org-info {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  font-size: 16px;
  color: #333333;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.org-action-buttons {
  border-radius: 50px; 
  margin-bottom: 30px; 
  padding: 10px 20px; 
  margin-right: 20px;
  margin-top: 20px;
  flex-grow: 1;
  width: fit-content;
}

.org-details {
  font-weight: 500;
  color: #1584ea;
}

.api-key {
  color: grey;
  font-weight: 300;
  cursor: pointer;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  padding-top: 60px;
}

.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 400px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.input-container {
  position: relative;
  width: 100%;
}

input[type="text"] {
  width: 100%;
  padding-right: 40px;
  padding: 10px;
  margin: 15px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.copy-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2rem;
  color: grey;
}

.copy-icon:hover {
  color: black;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .main-content {
    margin: 0 auto;
    width: 100%;
  }

  .welcome-message h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .active-org-info {
    flex-direction: column;
    text-align: center;
  }

  .org-action-buttons {
    margin: 10px 0;
    width: 100%;
    padding: 10px;
  }

  .org-button-container {
    flex-direction: column;
    gap: 10px;
  }

  .org-form {
    padding: 15px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 576px) {
  .org-action-buttons {
    padding: 8px;
    font-size: 14px;
  }

  .welcome-message h2 {
    font-size: 20px;
  }

  .active-org-info {
    padding: 15px;
  }

  .org-button-container {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .submit-btn {
    padding: 10px;
    font-size: 14px;
  }
}
</style>
