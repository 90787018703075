<template>
  <div class="row" style="width: 100%;">
    <div class="col-12 col-md-3">
      <SidebarMenu />
    </div>
    <div class="col-12 col-md-9 main-content" v-if="isAuthenticated">
      <div class="welcome-message">
        <h2>Welcome Back, </h2>
        <p>{{ user.name }}</p> 
      </div>
      <div class="header-stats-box" style="margin-bottom: 20px;">
        <h1>Did you know?</h1>
        <div v-if="carouselTips.length" class="carousel">
          <p>{{ carouselTips[currentTipIndex] }}</p>
        </div>
      </div>
      <div class="main-header-container">
        <div class="header-stats row">
          <div class="header-stats-box col-md-6 col-sm-12">
            <h1>Questions Asked Across Orgs</h1>
            <BarChart 
              :chartData="{
                labels: barChartLabel,
                datasets: [
                  {
                    label: 'Questions Asked',
                    backgroundColor: '#195aa0',
                    data: overAllOrgStats?.dailyTotals ? Object.values(overAllOrgStats.dailyTotals) : []
                  }
                ]
              }"
            />
          </div>
          <div class="header-stats-box col-md-6 col-sm-12">
            <h1>Performance Metrics</h1>
            <ul>
              <li><span class="emphasize-text">Average Time To Answer Across All Orgs:</span> {{ overAllOrgStats.avgTimeToAnswer }}</li>
              <li>More Metrics Coming Soon!</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="org-title">
        <p>Organizations</p>
      </div>
      <div class="org-list" v-if="dbOrgInfo.length > 0">
        <div v-for="(organization, index) in dbOrgInfo" :key="index" class="org-item">
          <div class="info-header">
            <div class="org-info-container">
              <h1 class="org-details">{{ organization.organizationName }}</h1>
              <div class="row">
                <div class="org-card-stats col-md-8">
                  <div>
                    <span class="emphasize-text">Organization Email: </span>{{ organization.organizationEmail }}
                  </div>
                  <div>
                    <span class="emphasize-text">Unanswered Questions: </span>{{ organization.orgStatistics.unansweredQuestions }}
                  </div>
                  <div>
                    <span class="emphasize-text">Answered Questions: </span>{{ organization.orgStatistics.answeredQuestions }}
                  </div>
                  <div>
                    <span class="emphasize-text">Average Time To Answer Questions:</span>{{ organization.orgStatistics.averageTimeToAnswer }}
                  </div>
                </div>
                <div class="pie-chart-container">
                  <PieChart :chartData="{
                      labels: ['Answered', 'Unanswered'],
                      datasets: [{
                        data: [organization.orgStatistics.answeredQuestions, organization.orgStatistics.unansweredQuestions],
                        backgroundColor: ['#2fcc5f', '#ff3d3d']
                      }]
                    }" 
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        No organizations found. Create an organization in the organizations tab.
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useAuthStore } from '@/stores/authStore';
import { useUserStore } from '@/stores/user';
import SidebarMenu from '@/components/SidebarMenu.vue';
import PieChart from '@/components/PieChart.vue';
import BarChart from '@/components/BarChart.vue'

export default {
  name: 'Home',
  components: {
    SidebarMenu,
    PieChart,
    BarChart
  },
  setup() {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const user = ref(authStore.user);
    const isAuthenticated = ref(authStore.isAuthenticated);
    const dbUserInfo = ref(null);
    const dbOrgInfo = ref([]);
    const currentTipIndex = ref(0);

    const carouselTips = ref([
      "You can manage your organization's FAQs in the Knowledge Base.",
      "Don't forget to check out the Dashboard for the latest statistics.",
      "Customize your notifications in the Settings section.",
    ]);

    const carouselStats = ref([])

    const barChartLabel = ref([]);

    const overAllOrgStats = ref({});

    const nextTip = () => {
      currentTipIndex.value = (currentTipIndex.value + 1) % carouselTips.value.length;
    };

    const getUserInfo = async () => {
      try {
        const response = await fetch(`/user/get-user-info?userId=${encodeURIComponent(user.value.sub)}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) throw new Error((await response.json()).error || 'Unknown error');
        dbUserInfo.value = await response.json();
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    const getOrganizationInfo = async () => {
      try {
        const response = await fetch(`/organization/get-organization-info?userId=${encodeURIComponent(user.value.sub)}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) throw new Error((await response.json()).error || 'Unknown error');
        dbOrgInfo.value = await response.json();
        overAllOrgStats.value = await getStatTotalsByDay();
      } catch (error) {
        console.error('Failed to fetch organization info:', error);
      }
    };

    const getDateLabels = () => {
      const days = [];
      const options = { weekday: 'short' }; 
      
      for (let i = 6; i >= 0; i--) {
        const date = new Date();
        date.setDate(date.getDate() - i);

        const dayOfWeek = date.toLocaleDateString('en-US', options); 
        const formattedDate = `${dayOfWeek}, ${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}`;
        
        days.push(formattedDate);
      }
      barChartLabel.value = days; 
    };


    const getStatTotalsByDay = async () => {
      if (dbOrgInfo.value) {
        const dailyTotals = {};
        let totalAverageTimeToAnswer = 0; 
        let totalOrganizations = dbOrgInfo.value.length; 

        dbOrgInfo.value.forEach(org => {
          const orgAvgTimeToAnswer = parseInt(org.orgStatistics.averageTimeToAnswer) || 0; 


          totalAverageTimeToAnswer += orgAvgTimeToAnswer;

          org.orgStatistics.questionsPerDay.forEach(day => {
            const date = day.questionDate;
            const questionCount = parseInt(day.questionCount) || 0;

            if (dailyTotals[date]) {
              dailyTotals[date] += questionCount;
            } else {
              dailyTotals[date] = questionCount;
            }
          });
        });

        const avgTimeToAnswer = totalOrganizations > 0 ? totalAverageTimeToAnswer / totalOrganizations : 0;

        return { dailyTotals, avgTimeToAnswer }; 
      }
    };



    onMounted(async () => {
      if (isAuthenticated.value) {
        await getUserInfo();
        if (dbUserInfo.value?.affiliatedOrg) {
          await getOrganizationInfo();
        }
      }
      getDateLabels();
      setInterval(nextTip, 5000); 
    });

    return {
      user,
      isAuthenticated,
      dbUserInfo,
      dbOrgInfo,
      carouselTips,
      currentTipIndex,
      barChartLabel,
      overAllOrgStats
    };
  },
};
</script>

<style scoped>
.navbar {
  margin: 0;
  padding-top: 10px;
  width: 100%;
  background-color: transparent !important;
  border: none;
  box-shadow: none;
}

.main-content {
  background-color: #f4f6f8;
  min-height: 100vh;
}

.welcome-message {
  display: flex;
  justify-content: start;
  align-items: end;
}

.welcome-message h2 {
  font-weight: 500;
  color: #195aa0;
  text-align: center;
  margin-bottom: 20px;
}
.welcome-message p {
  font-weight: 400;
  color: #195aa0;
  text-align: center;
  font-size: 1.85rem;
  margin-left:5px;
}

.main-header-container {
  background-color: #75b8ff73;
  border-radius: 15px;
  padding: 15px;
  width: 100%; 
  box-sizing: border-box; 
}

.header-stats {
  display: flex;
  justify-content: space-between; 
  gap: 20px; 
  padding-left: 12px;
  padding-right: 12px;
  gap: 10px;
}

.header-stats-box {
  background-color: rgba(255, 255, 255, 0.863);
  flex: 1 1 calc(50% - 20px); 
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; 
}
.header-stats-box h1 {
  text-align: center;
  font-size:1.5rem;
}


.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px; 
  text-align: center; 
}

.carousel p {
  font-size: 1.2rem;
  color: #333;
  margin: 0;
}
.org-title {
  margin-top: 20px;
}
.org-title p {
  font-weight: 500;
  color: #195aa0;
  font-size: 1.85rem;
  margin-left:5px;
}
.active-org-info {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  font-size: 16px;
  color: #333333;
  transition: max-height 0.3s ease;
  overflow: hidden;
  max-height: 80px;
  display: flex;
  flex-direction: column;
}


.info-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

.org-list {
  margin: -10px; 
  width:60%;            
}


.org-item {
  flex: 0 0 calc(50% - 20px);   
  box-sizing: border-box;
  margin: 10px;     
  margin-top:20px;          
}


.org-info-container {
  background-color: rgba(255, 255, 255, 0.863);
  border-radius: 8px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  padding: 15px;  
  width:100%;       
}

.org-details {
  font-weight: 500;
  color: #195aa0;
  margin-left:5px;
  text-align: center;
}

.pie-chart-container {
    width:200px;
    height:200px;
  }

.org-card-stats {
  display:flex;
  flex-direction: column;
  justify-content: space-around
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .main-content {
    margin: 0 auto;
    width: 100%;
  }
  .header-stats-box {
    flex: 1 1 100%; 
  }
  .welcome-message h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .active-org-info {
    flex-direction: column;
    text-align: center;
  }

  .org-action-buttons {
    margin: 10px 0;
    width: 100%;
    padding: 10px;
  }

  .org-button-container {
    flex-direction: column;
    gap: 10px;
  }

  .org-form {
    padding: 15px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 576px) {
  .org-action-buttons {
    padding: 8px;
    font-size: 14px;
  }

  .welcome-message h2 {
    font-size: 20px;
  }

  .active-org-info {
    padding: 15px;
  }
  .main-header-container {
    padding: 10px;
  }
  .header-stats {
    gap: 10px;
  }
  .header-stats-box {
    padding: 10px;
  }
}
.emphasize-text {
  font-weight:600;
}
</style>
