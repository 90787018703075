<template>
    <div class="chart-container">
        <Bar
            id="my-chart-id"
            :options="chartOptions"
            :data="chartData"
        />
    </div>
  </template>
  
  <script>
  import { defineComponent, ref } from 'vue'
  import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
  
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
  
  export default defineComponent({
    name: 'BarChart',
    components: { Bar },
    props: {
      chartData: {
        type: Object,
        required: true
      }
    },
    setup() {
      const chartOptions = ref({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true
          },
        },
        scales: {
            x:{
                grid: {
                    display:false
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1
                }
            }
        }
      })
  
      return {
        chartOptions
      }
    }
  })
  </script>
<style scoped>
.chart-container {
    height: 350px;
    padding: 20px;
    box-sizing: border-box;
    width:100%;
}

</style>
  